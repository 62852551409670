import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`June 23rd, 2021`}</h5>
    <h1>{`Machine Learning for Deliberative Democracy`}</h1>
    <h5>{`History, theory and applications of machine learning for collective intelligence and deliberative democracy`}</h5>
    <h2>{`Overview`}</h2>
    <p>{`Machine learning for deliberative democracy (ML-DELIB) is a course about the past, present and future role of machine intelligence in political systems. Ten modules cover a journey from fire control radars and self driving cars to government by algorithm, and ultimately the intentional design of collective intelligence in deliberative bodies like legislatures and citizens assemblies.`}</p>
    <p>{`The course is designed specifically to bridge political science and computer science, with an emphasis on political theory and the history of political representation on one hand, and applied statistics on the other.`}</p>
    <p>{`Machine learning provides sensory cababilities to computing systems, ultimately enabling machines to acheive goals on their own. Democratic systems involve individuals in the process of setting goals; identifying common goals and values is an important aspect of democratic systems. This course explores the task of of expanding social sensory capabilities in the interest of enhancing collective intelligence: that is, machine learning for deliberative democracy.`}</p>
    <p>{`This course asks: what are the implications of advancements in computation and communication for representative forms of government? Can we build intentional systems for collective intelligence? What's been tried? What forms of government might emerge if we succeed?`}</p>
    <h5>{`Course design, aspirations & learning outcomes`}</h5>
    <p>{`This course was designed by teaching it. I am indebted to a particulary motivated student who took the course as an independent study over the course of 2021. I am grateful for continued feedback on this content from anyone who comes across it.`}</p>
    <p>{`ML-DELIB is the course I wanted as an undergraduate in political science (and international relations) interested in computing and collective intelligence. It is intended to be equally interesting to computer science students who desire to apply their skills to public service. The course will also serve as meaningful background to anyone considering building a system for collective intelligence from scratch, or contributing to an existing one, including tech employees working on conversation systems. It may also be of interest to public servants and citizens who want to understand the context and trajectory of the ecosystem of collective intelligence for democratic practice.`}</p>
    <p>{`This course prepares students to think critically and take intentional actions. Actions might include building new software, running existing software, or applying methods involving software in real world situations. The first time this course was run, the exemplary project (see section 10 below) based half of the course resulted in a completely student run deliberation that involved 2% of the population of a rural US county (~400/~15,000 people) and had impact on land use policy — a high bar worth aiming for.`}</p>
    <h5>{`Computational Political Theory`}</h5>
    <p>{`What does an interdisciplinary mashup of political science and computer science look like? There are interesting hints of both theoretical and applied dimensions from approaches in computational sociology, including projects mentioned in this course like All Our Ideas and City Matrix and writing from Chris Bail and Zeynep Tufekci.`}</p>
    <p>{`I believe there's more room for exploration. Assumptions about limitations to the manner and rate of communication are deeply embedded into the design of governments in complex ways. Additionally, how citizens cohere and understand themselves have profound political implications. I hope this course will spark new ideas, connections and conversations.`}</p>
    <h5>{`Citing this syllabus`}</h5>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`Megill, Colin. Syllabus for "Machine Learning for Deliberative Democracy". January 2021. Available: colinmegill.com/ml-delib`}</inlineCode></p>
    </blockquote>
    <p>{`As this syllabus is an original work and happens to contain some language and sequencing which is also part of forthcoming written work, all rights are reserved.`}</p>
    <h5>{`Guide`}</h5>
    <p>{`All readings and documentaries are marked 📕 & 📽, and readings and media additionally marked with a 🌟 are required. Each section is roughly one week, for a total of 10 weeks of theory, to allow time for reading and content. The project based section of the course, which is of variable length, is not captured here except by example of previous student work.`}</p>
    <h5>{`Considerations for lecture`}</h5>
    <p>{`I tend to speak quickly, use dense phrases which sometimes include technical language, and make big jumps to connect disparate ideas. Clarifying questions are welcome and helpful to everyone, and give shape to the course. Questions make the course rich, and very little is really 'off topic'.`}</p>
    <p>{`You can safely assume I will take any amount of time to fill in any amount of background you need. The course has been designed to allow for side paths, even very long ones that may transform class periods. Since the course is interdisciplinary, I do not assume you already have all of the background knowledge. I also do not assume that explaining things once, from one angle, is sufficient. Do ask questions!`}</p>
    <h2>{`1. The Automatic Society`}</h2>
    <p>{`In this section, we consider automation as a process, across domains of human experience, past, present and future. This grounds us in both machine learning and societal implications from our first step.`}</p>
    <ul>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://www.theatlantic.com/ideas/archive/2019/06/give-us-fully-automated-luxury-communism/592099/"
        }}>{`Give Us Fully Automated Luxury Communism`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=PRg5RNU_JLk"
        }}>{`How do self driving cars "see"? — TedEd`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.wired.co.uk/article/farming-robots-small-robot-company-tractors"
        }}>{`How small robots may kill the tractor and make farming efficient`}</a></li>
      <li parentName="ul">{`📽 `}<a parentName="li" {...{
          "href": "https://twitter.com/Ronald_vanLoon/status/1509649302586601472?s=20&t=N8L0C5GJpoxnkEWLhOoILA"
        }}>{`SqUID`}</a></li>
    </ul>
    <h5>{`What examples of automation are you familiar with? Could humans create a fully automatic society?`}</h5>
    <ul>
      <li parentName="ul">{`Utopian visions`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Operating_Manual_for_Spaceship_Earth#Automation"
            }}>{`Spaceship Earth`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Dystopian visions`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=h1BQPV-iCkU"
            }}>{`WALL-E`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Agriculture, as an example`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=T7Os5Okf3OQ"
            }}>{`Autonomous tractors`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.technologyreview.com/2018/10/03/139937/new-autonomous-farm-wants-to-produce-food-without-human-workers/"
            }}>{`Robot hydroponics`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://farm.bot/"
            }}>{`Farm Bot`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.cognex.com/industries/food-and-beverage/product-quality-inspection/automated-meat-poultry-inspection"
            }}>{`Autonomous food safety systems`}</a></li>
          <li parentName="ul">{`Information intensive tasks like `}<a parentName="li" {...{
              "href": "https://eagronom.com/en_au/auto-crop-planner/"
            }}>{`crop rotation`}</a></li>
          <li parentName="ul">{`Can we automate an entire food systems, from tractor → farm → quality control & safety → transporting food → delivering food → preparing food → serving food`}</li>
          <li parentName="ul">{`What about land use regulation?`}</li>
        </ul>
      </li>
    </ul>
    <h5>{`Are we headed towards "post scarcity"? Are "solarpunk" or fusion possible?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Solarpunk"
        }}>{`https://en.wikipedia.org/wiki/Solarpunk`}</a></li>
    </ul>
    <h5>{`What parts of our economy are already being automated presently, and which ones will be next? What are the implications?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://public.tableau.com/profile/mckinsey.analytics#!/vizhome/AutomationandUSjobs/Technicalpotentialforautomation"
        }}>{`What roles are most likely to be automated, which least`}</a>{`?`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.roboticsproceedings.org/rss12/p36.pdf"
        }}>{`Lessons from the Amazon Picking Challenge: Four Aspects of Building Robotic Systems`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nber.org/system/files/working_papers/w28920/w28920.pdf"
        }}>{`TASKS, AUTOMATION, AND THE RISE IN US WAGE INEQUALITY`}</a></li>
    </ul>
    <h2>{`2. Systems with Goals — the Science of Control Through Communication`}</h2>
    <p>{`"Well, a new standpoint on communication means a new emphasis on communication as the binding factor of society. The same study of organization that will give us more information about what happens in the living body can give us more information about society. The automatic factory, the computing machine and so on which all are informational machines are having a secondary impact on society by modifying the world in which we live. Anything which changes the condition of society must be considered as a factor for good or for bad, and generally both for good and for bad. Already I should say the mere possibility of facing the automatic factory has changed our economic world more than we have realized. These may be healthy and they may be dangerous. They will only be healthy if we know enough about what is happening to be able to avoid the danger and apply cybernetics to human welfare." — Norbert Weiner`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`📕 🌟 `}<a parentName="p" {...{
            "href": "https://www.technologyreview.com/2018/12/19/138508/mighty-mouse/"
          }}>{`Shannon's mouse`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`📽 🌟 `}<a parentName="p" {...{
            "href": "https://norbertwiener.org/remaining-human/"
          }}>{`Remaining Human`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`📽 🌟 `}<a parentName="p" {...{
            "href": "https://www.youtube.com/watch?v=z2Whj_nL-x8&ab_channel=UniversityofCaliforniaTelevision%28UCTV%29"
          }}>{`Documentary on Claude Shannon's life`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`📕 🌟 `}<a parentName="p" {...{
            "href": "https://aeon.co/essays/how-the-soviets-invented-the-internet-and-why-it-didnt-work"
          }}>{`How the Soviets invented the internet and why it didn't work`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`📕 `}<a parentName="p" {...{
            "href": "https://www.quantamagazine.org/how-claude-shannons-concept-of-entropy-quantifies-information-20220906/"
          }}>{`How Shannon Entropy Imposes Fundamental Limits on Communication`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`📕 `}<a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/Intelligent_agent"
          }}>{`Intelligent Agent — Wikipedia`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`📕 `}<a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/Cybernetics"
          }}>{`Cybernetics — Wikipedia`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`📽 `}<a parentName="p" {...{
            "href": "https://archive.org/details/The_Information"
          }}>{`James Gleick talk on the information age`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`📕 `}<a parentName="p" {...{
            "href": "https://archive.org/details/darkheroofinform0000conw_p9d0/page/n7/mode/2up"
          }}>{`Dark hero of the information age pp103-194`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`📕 `}<a parentName="p" {...{
            "href": "https://www.amazon.com/Information-History-Theory-Flood/dp/1400096235"
          }}>{`The Information by James Gleick chapters 5-8`}</a></p>
      </li>
    </ul>
    <h5>{`What is an intelligent agent? Any examples you interact with regularly?`}</h5>
    <h5>{`What were the first agents?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Centrifugal_governor"
        }}>{`https://en.wikipedia.org/wiki/Centrifugal_governor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Governor_%28device%29"
        }}>{`https://en.wikipedia.org/wiki/Governor_%28device%29`}</a></li>
    </ul>
    <h5>{`What was cybernetics? What does feedback mean in this context?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.etymonline.com/search?q=regulation"
        }}>{`https://www.etymonline.com/search?q=regulation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.etymonline.com/word/regulate"
        }}>{`https://www.etymonline.com/word/regulate`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.etymonline.com/search?q=govern"
        }}>{`https://www.etymonline.com/search?q=govern`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.albert.io/blog/positive-negative-feedback-loops-biology/#:~:text=Feedback%20loops%20can%20also%20occur,which%20will%20increase%20predator%20numbers"
        }}>{`Predator prey relationships are an example of feedback`}</a>{`.`}</li>
      <li parentName="ul">{`Body temperature and `}<a parentName="li" {...{
          "href": "https://www.khanacademy.org/science/high-school-biology/hs-human-body-systems/hs-body-structure-and-homeostasis/a/homeostasis"
        }}>{`homeostasis`}</a>{` are also examples of feedback`}</li>
    </ul>
    <h5>{`What contributions did Claude Shannon make? Norbert Weiner?`}</h5>
    <h5>{`What happened to cybernetics as a field? What were the Macy conferences?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.art-sciencefactory.com/complexity-map_feb09.html"
        }}>{`The complexity map`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://asc-cybernetics.org/foundations/history/MacySummary.htm"
        }}>{`Summary of the Macy Conferences`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stream.syscoi.com/2019/06/05/looking-back-in-history-the-macy-conferences/"
        }}>{`Looking back at the Macy Conferences`}</a></li>
    </ul>
    <h5>{`How do planned economies differ from markets with regards to feedback?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Five-year_plans_for_the_national_economy_of_the_Soviet_Union"
        }}>{`https://en.wikipedia.org/wiki/Five-year_plans_for_the_national_economy_of_the_Soviet_Union`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.theguardian.com/world/gallery/2014/jul/14/soviet-union-collapse-in-pictures"
        }}>{`https://www.theguardian.com/world/gallery/2014/jul/14/soviet-union-collapse-in-pictures`}</a></li>
    </ul>
    <h5>{`How have armed conflicts spurred the development of intelligent agents?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://thefemalesoldier.com/blog/ack-ack-girls"
        }}>{`Ack Ack Girls`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/V-1_flying_bomb"
        }}>{`V-1 flying bomb`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/SCR-584_radar"
        }}>{`SCR-584 radar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=Zsf38NYzo5Q&ab_channel=AiirSourceMilitary"
        }}>{`CWIZ`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.darpa.mil/news-events/2020-10-07"
        }}>{`DARPA Challenges`}</a></li>
    </ul>
    <h2>{`3. Ways Machines Think`}</h2>
    <p>{`What are the types of machine learning and what are they used for?`}</p>
    <p>{`We are locating the work that we're talking about regarding AI for deliberation, in the broader context of machine learning and machine intelligence, as well as societal implications of each form of AI.`}</p>
    <ul>
      <li parentName="ul">{`📽 🌟 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=WXuK6gekU1Y&ab_channel=DeepMind"
        }}>{`AlphaGo Documentary`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "http://d2l.ai/chapter_introduction/index.html#kinds-of-machine-learning-problems"
        }}>{`Kinds of Machine Learning Problems`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://qz.com/1034972/the-data-that-changed-the-direction-of-ai-research-and-possibly-the-world/"
        }}>{`The data that changed the direction of AI research and possibly the world`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.amazon.com/How-Smart-Machines-Think-Press/dp/0262038404"
        }}>{`https://www.amazon.com/How-Smart-Machines-Think-Press/dp/0262038404`}</a></li>
      <li parentName="ul">{`📽 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=GvYYFloV0aA&list=PL8dPuuaLjXtO65LeD2p4_Sb5XQ51par_b"
        }}>{`Crash Course AI`}</a></li>
    </ul>
    <h5>{`What is supervised learning?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.bloomberg.com/news/articles/2021-01-21/waymo-self-driving-taxis-are-coming-to-more-u-s-cities"
        }}>{`https://www.bloomberg.com/news/articles/2021-01-21/waymo-self-driving-taxis-are-coming-to-more-u-s-cities`}</a></li>
    </ul>
    <h5>{`What is unsupervised learning?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://setosa.io/ev/principal-component-analysis/"
        }}>{`https://setosa.io/ev/principal-component-analysis/`}</a></li>
    </ul>
    <h5>{`What is reinforcement learning?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=nIgIv4IfJ6s"
        }}>{`Reinforcement Learning: Crash Course AI #9`}</a></li>
    </ul>
    <h5>{`What is gradient descent? Local minima & global minima?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://towardsdatascience.com/a-visual-explanation-of-gradient-descent-methods-momentum-adagrad-rmsprop-adam-f898b102325c"
        }}>{`A visual explanation of gradient descent`}</a></li>
    </ul>
    <h5>{`What are neural networks? What is deep learning?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://robotics.umich.edu/research/focus-areas/deep-learning-for-robotics/"
        }}>{`https://robotics.umich.edu/research/focus-areas/deep-learning-for-robotics/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://computerhistory.org/blog/how-do-neural-network-systems-work/"
        }}>{`https://computerhistory.org/blog/how-do-neural-network-systems-work/`}</a></li>
      <li parentName="ul">{`Neural nets 3blue1brown `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=aircAruvnKk&ab_channel=3Blue1Brown"
        }}>{`https://www.youtube.com/watch?v=aircAruvnKk&ab_channel=3Blue1Brown`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://news.mit.edu/2017/explained-neural-networks-deep-learning-0414"
        }}>{`https://news.mit.edu/2017/explained-neural-networks-deep-learning-0414`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://playground.tensorflow.org/#activation=tanh&batchSize=10&dataset=circle&regDataset=reg-plane&learningRate=0.03&regularizationRate=0&noise=0&networkShape=4,2&seed=0.42565&showTestData=false&discretize=false&percTrainData=50&x=true&y=true&xTimesY=false&xSquared=false&ySquared=false&cosX=false&sinX=false&cosY=false&sinY=false&collectStats=false&problem=classification&initZero=false&hideText=false"
        }}>{`Tensorflow Playground`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://distill.pub/2017/feature-visualization/"
        }}>{`Feature visualization`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ml4a.github.io/ml4a/looking_inside_neural_nets/"
        }}>{`Looking inside neural nets`}</a></li>
    </ul>
    <h5>{`Previous versions of computer programs couldn't beat children. There were other reasons Korean society didn't believe that a machine could play this game well. What were those reasons?`}</h5>
    <h5>{`What do we mean when we say human intuition? What probability did AlphaGo assign to Lee Sedol's "God move" being played by a human?`}</h5>
    <h5>{`Do humans assess the world probabilistically? Why or why not, or when and when not?`}</h5>
    <h5>{`What are weak and strong AI, and what are the differences?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ibm.com/cloud/learn/strong-ai#:~:text=Weak%20AI%2C%20also%20known%20as,to%20solve%20for%20new%20problems"
        }}>{`Weak vs Strong AI`}</a></li>
    </ul>
    <h5>{`What is Baysian statistics?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=9TDjifpGj-k"
        }}>{`You Know I'm All About that Bayes`}</a></li>
    </ul>
    <h5>{`What is a high dimensional space? What is a latent space?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dimensionality-reduction-293e465c2a3443e8941b016d.vercel.app/"
        }}>{`The Beginner's Guide to Dimensionality Reduction`}</a></li>
    </ul>
    <h2>{`4. New Tools in Old Societies`}</h2>
    <p>{`How can applying machine learning to society go wrong?`}</p>
    <ul>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://www.nytimes.com/2013/06/23/magazine/the-obama-campaigns-digital-masterminds-cash-in.html"
        }}>{`The Obama Campaign's Digital Masterminds Cash In`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://www.propublica.org/article/machine-bias-risk-assessments-in-criminal-sentencing"
        }}>{`Machine bias`}</a></li>
      <li parentName="ul">{`📽 🌟 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=gdCJYsKlX_Y"
        }}>{`Weapons of Math Destruction by Cathy O'Neill`}</a>{` & `}<a parentName="li" {...{
          "href": "https://www.amazon.com/Weapons-Math-Destruction-Increases-Inequality/dp/0553418831/"
        }}>{`book`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://www.nytimes.com/2017/09/18/world/europe/stanislav-petrov-nuclear-war-dead.html"
        }}>{`How Stanislav Petrov stopped a nuclear war`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.amazon.com/Race-After-Technology-Abolitionist-Tools/dp/1509526404/"
        }}>{`Race After Technology: Abolitionist Tools for the New Jim Code`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.amazon.com/Algorithms-Oppression-Search-Engines-Reinforce/dp/1479837245/"
        }}>{`Algorithms of Oppression: How Search Engines Reinforce Racism Illustrated Edition`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://twitter.com/sibal_prateek/status/1503496330433413124"
        }}>{`UNESCO MOOC: Artificial Intelligence and The Rule of Law`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://datasociety.net/wp-content/uploads/2018/10/DS_Digital_Influence_Machine.pdf"
        }}>{`Data & Society report`}</a></li>
      <li parentName="ul">{`📕 Breaking the Social Media Prism by Chris Bail`}</li>
      <li parentName="ul">{`📽 `}<a parentName="li" {...{
          "href": "https://www.ted.com/talks/wael_ghonim_inside_the_egyptian_revolution?language=en"
        }}>{`https://www.ted.com/talks/wael_ghonim_inside_the_egyptian_revolution?language=en`}</a></li>
    </ul>
    <h5>{`Should there be a Hippocratic Oath for Data Science?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ncbi.nlm.nih.gov/books/NBK532770/"
        }}>{`https://www.ncbi.nlm.nih.gov/books/NBK532770/`}</a></li>
    </ul>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/LongResilience/status/1499442620992479246?s=20&t=7aarITEqrdst-B7ZneFBYw"
        }}>{`https://twitter.com/LongResilience/status/1499442620992479246?s=20&t=7aarITEqrdst-B7ZneFBYw`}</a></li>
    </ul>
    <h5>{`What is the "digital influence machine"?`}</h5>
    <ul>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://datasociety.net/wp-content/uploads/2018/10/DS_Digital_Influence_Machine.pdf"
        }}>{`https://datasociety.net/wp-content/uploads/2018/10/DS_Digital_Influence_Machine.pdf`}</a></li>
    </ul>
    <h5>{`What is the "attention economy"?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Attention_economy#:~:text=Attention%20economics%20is%20an%20approach,In%20this%20perspective%20Thomas%20H"
        }}>{`https://en.wikipedia.org/wiki/Attention_economy#:~:text=Attention%20economics%20is%20an%20approach,In%20this%20perspective%20Thomas%20H`}</a>{`.`}</li>
    </ul>
    <h5>{`Why was Obama's digital campaign glorified but Trump's vilified?`}</h5>
    <h5>{`What is a recommender engine? How does TikTok's recommendation system work?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://techcrunch.com/2020/06/18/tiktok-explains-how-the-recommendation-system-behind-its-for-you-feed-works/"
        }}>{`https://techcrunch.com/2020/06/18/tiktok-explains-how-the-recommendation-system-behind-its-for-you-feed-works/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=HEqQ2_1XRTs"
        }}>{`Reinforcement learning on YouTube`}</a></li>
    </ul>
    <h5>{`How does YouTube's recommendation system work? Why was it implicated in the Christchurch massacre in NZ?`}</h5>
    <h5>{`What is NLP, and what is a large language model? What is GPT-3?`}</h5>
    <ul>
      <li parentName="ul">{`LLM `}<a parentName="li" {...{
          "href": "https://spectrum.ieee.org/tech-talk/artificial-intelligence/machine-learning/open-ais-powerful-text-generating-tool-is-ready-for-business"
        }}>{`https://spectrum.ieee.org/tech-talk/artificial-intelligence/machine-learning/open-ais-powerful-text-generating-tool-is-ready-for-business`}</a></li>
    </ul>
    <h5>{`How is a recommender engine an example of collective intelligence?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Recommender_system"
        }}>{`https://en.wikipedia.org/wiki/Recommender_system`}</a>{` (especially reinforcement learning for recommender systems)`}</li>
    </ul>
    <h2>{`5. Will an AI eventually be a government?`}</h2>
    <p>{`Our possible cyberpunk futures`}</p>
    <ul>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Government_by_algorithm#In_popular_culture"
        }}>{`Government by algorithm`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://beyondtransparency.org/chapters/part-5/open-data-and-algorithmic-regulation/"
        }}>{`Algorithmic Regulation`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Multivac"
        }}>{`Multivac`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://www.sciencedirect.com/science/article/pii/S0160791X19305925"
        }}>{`A shallow defence of a technocracy of artificial intelligence: Examining the political harms of algorithmic governance in the domain of government`}</a></li>
      <li parentName="ul">{`📽 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=r13I-TuDcWI"
        }}>{`Hal reports on the state of the USS Discovery One in 2001: A Space Odyssey`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://evangelion.fandom.com/wiki/Magi"
        }}>{`The Magi (Neon Genesis Evangelion)`}</a></li>
      <li parentName="ul">{`📽 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=XyqCh5T5FaE&t=1s"
        }}>{`The Magi are real?`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://onlinelibrary.wiley.com/doi/full/10.1111/rego.12367"
        }}>{`Algorithmic governance: A modes of governance approach`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.sciencedirect.com/science/article/abs/pii/S0040162503000970?via%3Dihub"
        }}>{`“Smart machines” as philosopher kings`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://allpoetry.com/All-Watched-Over-By-Machines-Of-Loving-Grace"
        }}>{`Watched over by machines of loving grace`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Daemon_(novel_series)"
        }}>{`Daemon`}{`_`}{`(novel_series)`}</a></li>
    </ul>
    <h5>{`Since we know humans are flawed and subject to all sorts of predictible cognitive errors and corruption, should we ultimately aim to give up power to intelligent machines?`}</h5>
    <h5>{`Is giving up power to an algorithm what we've already done with constitutions? Are constitutions an "analog" version of this? Why or why not?`}</h5>
    <h2>{`6. Computational Deliberation`}</h2>
    <p>{`In this section, we discuss a possible middle ground for the role of artificial intelligence between 'no role' on one hand and 'total control' like 'Multivac' on the other: computational deliberation. The role of the computer in this scenario is to organize the communication of the population and facilitate deliberation, agenda setting, and decisionmaking.`}</p>
    <ul>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://www.twitterandteargas.org/"
        }}>{`Twitter & Tear Gas by Zeynep Tufekci`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://fourminutebooks.com/the-wisdom-of-crowds-summary/"
        }}>{`4 minute books: Wisdom of Crowds`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://www.newyorker.com/tech/annals-of-technology/spain-politics-via-reddit"
        }}>{`Politics via Reddit — New Yorker`}</a></li>
      <li parentName="ul">{`📕 🌟 Page 26, literature review `}<a parentName="li" {...{
          "href": "https://demos.co.uk/wp-content/uploads/2020/08/Polis-the-Political-Process-NEW.pdf"
        }}>{`DEMOS Report: Polis and the Political System`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://web.archive.org/web/20180702093635/https://civichall.org/civicist/vtaiwan-democracy-frontier/"
        }}>{`VTAIWAN: PUBLIC PARTICIPATION METHODS ON THE CYBERPUNK FRONTIER OF DEMOCRACY`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.economist.com/christmas-specials/2003/12/18/the-internet-in-a-cup"
        }}>{`The Internet in a Cup — Economist`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.wired.com/story/collective-intelligence-democracy/"
        }}>{`To Fix Tech, Democracy Has to Grow Up`}</a></li>
    </ul>
    <h5>{`How has machine intelligence been used to try to assist or augment in deliberation?`}</h5>
    <h5>{`What impact did Twitter have on the Arab Spring?`}</h5>
    <h5>{`What was the Delphi Method? How was it used during the Cold War?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://web.njit.edu/~turoff/pubs/delphibook/index.html"
        }}>{`https://web.njit.edu/~turoff/pubs/delphibook/index.html`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.investopedia.com/terms/d/delphi-method.asp#:~:text=The%20Delphi%20method%20is%20a%20process%20used%20to%20arrive%20at,the%20group%20after%20each%20round"
        }}>{`https://www.investopedia.com/terms/d/delphi-method.asp#:~:text=The%20Delphi%20method%20is%20a%20process%20used%20to%20arrive%20at,the%20group%20after%20each%20round`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Planning_poker"
        }}>{`https://en.wikipedia.org/wiki/Planning_poker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Wideband_delphi"
        }}>{`https://en.wikipedia.org/wiki/Wideband_delphi`}</a></li>
    </ul>
    <h5>{`What does the phrase "wisdom of crowds" mean, and does it really work?`}</h5>
    <h5>{`What is Q methodology?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Q_methodology"
        }}>{`https://en.wikipedia.org/wiki/Q_methodology`}</a></li>
    </ul>
    <h5>{`What's a wikisurvey? What is All Our Ideas?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nesta.org.uk/blog/crowdsourcing-democracy-using-wikisurveys/"
        }}>{`https://www.nesta.org.uk/blog/crowdsourcing-democracy-using-wikisurveys/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://compdemocracy.org/Wikisurvey/"
        }}>{`https://compdemocracy.org/Wikisurvey/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0123483"
        }}>{`https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0123483`}</a></li>
    </ul>
    <h5>{`What are the relative strengths of conversation platforms Reddit, Twitter, Argumen, Loomio?`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.arguman.org/a-universal-basic-income-is-the-best-way-to-eradicate-economic-inequality"
        }}>{`https://en.arguman.org/a-universal-basic-income-is-the-best-way-to-eradicate-economic-inequality`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.arguman.org/a-vegan-diet-is-the-most-ethically-viable"
        }}>{`https://en.arguman.org/a-vegan-diet-is-the-most-ethically-viable`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.arguman.org/a-creator-must-exist"
        }}>{`https://en.arguman.org/a-creator-must-exist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.wired.com/2014/04/loomio/"
        }}>{`https://www.wired.com/2014/04/loomio/`}</a></li>
    </ul>
    <h5>{`How did vTaiwan work? How does Polis work?`}</h5>
    <ul>
      <li parentName="ul">{`Polis / vTaiwan`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.technologyreview.com/2018/08/21/240284/the-simple-but-ingenious-system-taiwan-uses-to-crowdsource-its-laws/"
            }}>{`The simple but ingenius system Taiwan uses to crowdsource its laws`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://twitter.com/colinmegill/status/1445044310722822147"
            }}>{`Polis: Scaling Deliberation by Mapping High Dimensional Opinion Spaces`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://nutcroft.com/blog/polis-is-quintessential-digital-democracy/"
            }}>{`Pol.is is quintessential digital democracy`}</a></li>
        </ul>
      </li>
    </ul>
    <h5>{`Have there been attempts to apply natural language processing methods to deliberation?`}</h5>
    <ul>
      <li parentName="ul">{`Cortico & MIT CCC`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://cortico.ai/"
            }}>{`https://cortico.ai/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.ccc.mit.edu/"
            }}>{`https://www.ccc.mit.edu/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.ccc.mit.edu/realtalkmit/"
            }}>{`https://www.ccc.mit.edu/realtalkmit/`}</a>{` `}</li>
        </ul>
      </li>
      <li parentName="ul">{`Wikum`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://wikum.csail.mit.edu/"
            }}>{`http://wikum.csail.mit.edu/`}</a></li>
        </ul>
      </li>
    </ul>
    <h5>{`What other tools exist in this space which apply machine intelligence to deliberation?`}</h5>
    <ul>
      <li parentName="ul">{`Cesar Hidalgo & ANITI`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.ted.com/talks/cesar_hidalgo_a_bold_idea_to_replace_politicians?language=en"
            }}>{`https://www.ted.com/talks/cesar_hidalgo_a_bold_idea_to_replace_politicians?language=en`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.nature.com/articles/d41586-019-03557-6"
            }}>{`https://www.nature.com/articles/d41586-019-03557-6`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`MIT Moral Machine`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.moralmachine.net/"
            }}>{`https://www.moralmachine.net/`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Synthesis of many responses into a 'meta' response`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://patents.google.com/patent/US20150363696A1/"
            }}>{`https://patents.google.com/patent/US20150363696A1/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://aiforsocialgood.github.io/neurips2019/acceptedpapers_track1.htm"
            }}>{`https://aiforsocialgood.github.io/neurips2019/acceptedpapers_track1.htm`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://aiforsocialgood.github.io/neurips2019/accepted/track1/pdfs/105_aisg_neurips2019.pdf"
            }}>{`https://aiforsocialgood.github.io/neurips2019/accepted/track1/pdfs/105_aisg_neurips2019.pdf`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://aiforsocialgood.github.io/neurips2019/accepted/track1/posters/105_aisg_neurips2019.pdf"
            }}>{`https://aiforsocialgood.github.io/neurips2019/accepted/track1/posters/105_aisg_neurips2019.pdf`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`City Matrix`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.media.mit.edu/publications/citymatrix/"
            }}>{`https://www.media.mit.edu/publications/citymatrix/`}</a></li>
          <li parentName="ul">{`CityMatrix paper: `}<a parentName="li" {...{
              "href": "https://dspace.mit.edu/handle/1721.1/114059"
            }}>{`https://dspace.mit.edu/handle/1721.1/114059`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=CqUhiaOOqyA&feature=emb_logo"
            }}>{`https://www.youtube.com/watch?v=CqUhiaOOqyA&feature=emb_logo`}</a></li>
        </ul>
      </li>
    </ul>
    <h5>{`What might the next generation of these systems look like? How might they be applied to processes?`}</h5>
    <h2>{`7. Collective Intelligence`}</h2>
    <p>{`What is the goal of computational deliberation? How do we frame the pursuit?`}</p>
    <ul>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://www.amazon.com/Big-Mind-Collective-Intelligence-Change/dp/0691170797"
        }}>{`Big Mind by Geoff Mulgan`}</a>{` start with pages 237 and 238 which summarize the overall argument. Pp. 9-47 & ch. 9`}</li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.amazon.com/Superminds-Surprising-Computers-Thinking-Together/dp/0316349135"
        }}>{`Superminds by Thomas W. Malone (MIT)`}</a>{` Introduction through Page 82, the end of Part II`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nature.com/articles/d41586-021-01170-0"
        }}>{`Cooperative AI (Nature) by DeepMind`}</a></li>
    </ul>
    <h5>{`How do we define collective intelligence?`}</h5>
    <h5>{`What is complexity and what's the history of the field — is it a field like other fields?`}</h5>
    <h5>{`What is a complex adaptive system?`}</h5>
    <h5>{`Is a democracy a collective intelligence?`}</h5>
    <h2>{`8. The History of Political Representation Through New Eyes`}</h2>
    <p>{`What were the operating assumptions when designing democracies, previous to machine intelligence? What areas of constitutional design might be interesting to revisit?`}</p>
    <ul>
      <li parentName="ul">{`📽 🌟 `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=FNL22RvFwn0"
        }}>{`Carl Miller, TedXAthens`}</a></li>
      <li parentName="ul">{`📕 🌟 History of representation `}<a parentName="li" {...{
          "href": "https://plato.stanford.edu/entries/political-representation/"
        }}>{`https://plato.stanford.edu/entries/political-representation/`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://www.amazon.com/Open-Democracy-Reinventing-Popular-Century/dp/0691181993"
        }}>{`Open Democracy`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://ia800304.us.archive.org/26/items/OpenSourceDemocracy/OpenSourceDemocracy.pdf"
        }}>{`Open Source Democracy by Douglas Rushkoff`}</a></li>
      <li parentName="ul">{`📕 🌟 Paul Cartledge `}<a parentName="li" {...{
          "href": "https://www.historyextra.com/period/ancient-greece/how-to-build-a-democracy-athenian-style/"
        }}>{`https://www.historyextra.com/period/ancient-greece/how-to-build-a-democracy-athenian-style/`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.amazon.com/Toward-Democracy-The-Struggle-for-Self-Rule-in-European-and-American-Thought/dp/019505461X/"
        }}>{`Toward Democracy`}</a></li>
      <li parentName="ul">{`📕 Democracy, A Life `}<a parentName="li" {...{
          "href": "https://www.amazon.com/Democracy-Life-Paul-Cartledge/dp/0190866276"
        }}>{`https://www.amazon.com/Democracy-Life-Paul-Cartledge/dp/0190866276`}</a></li>
      <li parentName="ul">{`The Californian Ideology, Richard Barbrook `}<a parentName="li" {...{
          "href": "http://www.comune.torino.it/gioart/big/bigguest/riflessioni/californian_engl.pdf"
        }}>{`http://www.comune.torino.it/gioart/big/bigguest/riflessioni/californian_engl.pdf`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://lareviewofbooks.org/article/democracy-the-best-of-the-worst-a-conversation-with-paul-cartledge/"
        }}>{`https://lareviewofbooks.org/article/democracy-the-best-of-the-worst-a-conversation-with-paul-cartledge/`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.amazon.com/Death-Gods-Global-Power-Grab/dp/1785151339"
        }}>{`Death of the Gods by Carl Miller`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.penguinrandomhouse.com/books/552622/the-quiet-before-by-gal-beckerman/"
        }}>{`The Quiet Before by Gal Beckerman`}</a></li>
      <li parentName="ul">{`📰 `}<a parentName="li" {...{
          "href": "https://www.nytimes.com/2022/07/26/opinion/ezra-klein-podcast-sean-illing.html"
        }}>{`How We Communicate Will Decide Whether Democracy Lives or Dies`}</a></li>
      <li parentName="ul">{`📻 `}<a parentName="li" {...{
          "href": "https://open.spotify.com/episode/2RS6m8pnsyQqFiZfy1GoOr?si=8c2c58c6c36843a2"
        }}>{`The Mid-Century Media Theorists Who Saw What Was Coming`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.oecd.org/gov/innovative-citizen-participation.htm"
        }}>{`OECD report on innovative citizen participation`}</a></li>
      <li parentName="ul">{`📕 `}<a parentName="li" {...{
          "href": "https://www.vox.com/2018/6/4/17417452/congress-representation-ratio-district-size-chart-graph"
        }}>{`US Congress representation ratio district size — Vox`}</a></li>
    </ul>
    <h5>{`How do modern notions of 'representation' differ from ancient notions?`}</h5>
    <h5>{`What was the Kleroterion and how was it used?`}</h5>
    <h5>{`How do political parties create platforms?`}</h5>
    <h5>{`What is the history of the size (number of members) of the US Congress?`}</h5>
    <h2>{`9. Systems designed to find goals`}</h2>
    <ul>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://www.newyorker.com/magazine/2020/08/03/how-the-simulmatics-corporation-invented-the-future"
        }}>{`How the Simulmatics Corporation Invented the Future — New Yorker`}</a></li>
      <li parentName="ul">{`📕 🌟 `}<a parentName="li" {...{
          "href": "https://web.archive.org/web/20201022032414/https://civichall.org/civicist/beyond-flatland-machine-learning-end-two-party-binary/"
        }}>{`Beyond Flatland`}</a></li>
    </ul>
    <h5>{`Course wrap up, reflection`}</h5>
    <h2>{`10. Final project: doing this work in the world`}</h2>
    <h5>{`Running a computationally enabled deliberative exercise (CEDE)`}</h5>
    <p>{`Example student work from San Juan Islands, WA, United States`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/compdemocracy/openData/tree/master/ssis.land-bank-farmland.2rumnecbeh.2021-08-01"
        }}>{`open data`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://deepnote.com/project/San-Juan-Islands-Land-Use-Conversation-July-2021-BdvSVgcJTdGrU3iTuXLWPw/%2Fnotebook.ipynb"
        }}>{`open notebook`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://sjclandbank.org/pol-is-community-discussion/"
        }}>{`San Juan County Conservation Land Bank writeup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://sjclandbank.org/wp-content/uploads/2021/10/Final-Report_-Public-Discussion-on-the-San-Juan-County-Land-Bank-2.pdf"
        }}>{`Final report delivered to the Land Bank, county government, media and citizens`}</a></li>
    </ul>
    <h5>{`Maintaining existing systems for computational deliberation`}</h5>
    <h5>{`Designing and building new systems`}</h5>
    <h2>{`Additional resources`}</h2>
    <h5>{`Organizations & People`}</h5>
    <p>{`Aggregated as a twitter list: `}<a parentName="p" {...{
        "href": "https://twitter.com/i/lists/1499232866810548226"
      }}>{`ml-delib twitter list`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      